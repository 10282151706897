import Svgs from "svgs";
import Dashboard from "views/teacher/dashboard";
// import Modules from "views/admin/modules";
// import Students from "views/teacher/students";
import Students from "views/admin/students";
import Modules from "views/admin/modules";
import Lectures from "views/teacher/lectures";
import Announcement from "views/teacher/announcement";
import Assignment from "views/teacher/assignment";

export const teacherRouteList = [
  {
    path: "/teacher/",
    element: <Dashboard />,
    name: "Home",
    icon: <Svgs.Home />,
  },
  {
    path: "/teacher/students/",
    element: <Students type="teacher" />,
    name: "Students",
    icon: <Svgs.Students />,
  },
  {
    path: "/teacher/modules/",
    element: <Modules type={"teacher"} />,
    name: "Modules",
    icon: <Svgs.Modules />,
  },
  // {
  //   path: "/teacher/lectures/",
  //   element: <Lectures />,
  //   name: "Lectures",
  //   icon: <Svgs.Lectures />,
  // },
  {
    path: "/teacher/Announcement/",
    element: <Announcement />,
    name: "Announcement",
    icon: <Svgs.Announcements />,
  },
  {
    path: "/teacher/Assignment/",
    element: <Assignment />,
    name: "Assignment",
    icon: <Svgs.Assignments />,
  },
];
