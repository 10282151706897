import { course } from 'data/api';
import { useQuery, useMutation } from 'react-query';
import { GET } from 'api/common';

export const getStudentModulesData = async (id) => {
    if (id) {
        const data = await GET(`${course.get_student_modules}/${id}`, null);
        return data;
    }
};

export const useStudentModuleQuery = (id) => {
    return useQuery('GetStudentModules', () => getStudentModulesData(id));
};

export const useGetModuleMutation = () => {
    return useMutation(getStudentModulesData);
};
