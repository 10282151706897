import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { POST } from 'api/common'
import Button from 'components/common/atoms/button'
import Input from 'components/common/atoms/input'
import Onboarding from 'components/layout/onboarding'
import { BASE_URL, course } from 'data/api'
import useCustomFormik from 'form'
import { formSchema } from 'form/formSchema'
import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

const PayNow = () => {
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [payFee, setPayFee] = useState({ success: false, data: null });
    const price_obj = {
        "quarter": 3,
        "half": 2,
        "full": 1,
    }
    function getFirstUnpaidInstallment(installments) {
        for (let i = 0; i < installments.length; i++) {
            if (installments[i].paid_status === "0") {
                return installments[i];
            }
        }
        return null;
    }
    const onSubmit = async (values) => {
        setIsLoading(true)
        const data = await POST("student_pay_fee_details", values);
        setIsLoading(false)
        if (data?.success) {
            let unPaid = getFirstUnpaidInstallment(data?.data.payment_fee);
            const totalPrice = (+unPaid.installment_amount).toFixed(2);
            const installment_number = unPaid.installment_number;
            console.log({ success: true, data: data?.data, total_fee: totalPrice });
            setPayFee({ success: true, data: data?.data, total_fee: totalPrice, installment_number })
        }
    };

    const validationSchema = {
        student_id: formSchema.text,
    }
    const initialValues = {
        student_id: "",
    }
    const form = useCustomFormik({ onSubmit, validationSchema, initialValues });

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true)

        if (!stripe || !elements) {
            return;
        }

        const cardElement = elements.getElement(CardElement);

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        if (error) {
            // console.error(error);
            toast.error(error.message)
        } else {
            console.log("API");
            const data = await POST("student_pay_installment", {
                "student_id": payFee?.data?.id,
                "installment_number": payFee?.installment_number
            });
            console.log(data);
            toast.success("Fee has been submitted successfully!");
            navigate("/")
        }
        setLoading(false)
    };

    const cardElementOptions = {
        style: {
            base: {
                fontSize: '16px',
                color: '#333',
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a',
            },
        },
        hidePostalCode: true,
    };
    return (
        <Onboarding>
            {
                payFee.success ? <div className='flex flex-col gap-3'>
                    <div>
                        <div className='font-semibold text-2xl'><span className=''>Welcome,</span> {payFee?.data?.first_name ? payFee?.data?.first_name : ""} {payFee?.data?.last_name ? payFee?.data?.last_name : ""}</div>
                        <p className='text-gray-400'>Enter the card details below to pay</p>
                        <p>Total Fee: <span className='font-semibold text-2xl'>${payFee?.total_fee}</span></p>
                    </div>
                    <div className='border rounded-md px-3 py-3'>
                        <CardElement options={cardElementOptions} />
                    </div>
                    <Button isLoading={loading} onClick={handleSubmit}>Pay Now</Button>
                    <div>
                        <p className='text-gray-400 text-sm'>Note: If you have any issues with the payment, please contact the support team.</p>
                    </div>
                    <div className='mt-[2rem]'>
                        <img src={`${process.env.PUBLIC_URL}/assets/imgs/payments.png`} alt="" className='h-[4.25rem] w-auto object-contain mx-auto' />
                    </div>
                </div> : <form onSubmit={form.handleSubmit} className='flex flex-col gap-3'>
                    <Input form={form} name={"student_id"} label={"Please enter your student ID"} placeholder={'Add student ID'} />
                    <Button type={"submit"} isLoading={isLoading}>Submit</Button>
                </form>
            }

        </Onboarding>
    )
}

export default PayNow