import React, { useEffect, useState } from 'react';
import { formSchema } from 'form/formSchema';
import useCustomFormik from 'form';
import Input from 'components/common/atoms/input';
import Dropdown from 'components/common/atoms/dropdown';
import Button from 'components/common/atoms/button';
import StudentContainer from 'components/layout/student-container';
import { FieldArray, Formik, useFormikContext } from 'formik';
import toast from 'react-hot-toast';
import { useAddStudentFormMutation } from 'api/student/student-form';
import * as Yup from "yup"
import { useNavigate } from 'react-router-dom';
import { useGetModuleWithoutAuthQuery } from 'api/modules/get-without-auth';
import { useGetDiplomaWithoutAuthQuery } from 'api/diploma/get';
import { nationalities } from 'data/common';
import FileInput from 'components/common/atoms/fileInput';

const StudentForm = () => {
  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Course title is required'),
    apply_date: Yup.date().required('Apply date is required'),
    time: Yup.boolean().required('Time is required'),
    level_of_entry: Yup.string().required('Level of entry is required'),
    personal_title: Yup.string().required('Personal title is required'),
    // first_name: Yup.string().required('First name is required'),
    // last_name: Yup.string().required('Last name is required'),
    gender: Yup.string().required('Gender is required'),
    date_of_birth: Yup.date().required('Date of birth is required'),
    // applicant_name: Yup.string().required('Applicant name is required'),
    family_name: Yup.string().required('Family name is required'),
    p_address: Yup.string().required('Permanent address is required'),
    country: Yup.string().required('Country is required'),
    post_code: Yup.string().required('Post code is required'),
    mobile: Yup.string().required('Mobile is required'),
    email_address: Yup.string().email('Invalid email address').required('Email address is required'),
    nationality: Yup.string().required('Nationality is required'),
    // professionalBodyName: Yup.string().required('Professional body name is required'),
    // professionalBodyRegNo: Yup.string().required('Professional body registration number is required'),
    previousStudyUK: Yup.boolean().required('Previous study in UK is required'),
    // previousStudyLevel: Yup.string().required('Previous study level is required'),
    previousStudyLCPS: Yup.boolean().required('Previous study at LCPS is required'),
    highestQualification: Yup.string().required('Highest qualification is required'),
    agentUsed: Yup.boolean().required('Agent used is required'),
    // referee1Name: Yup.string().required('Referee 1 name is required'),
    // referee1Address: Yup.string().required('Referee 1 address is required'),
    // referee1Postcode: Yup.string().required('Referee 1 postcode is required'),
    // referee1Telephone: Yup.string().required('Referee 1 telephone is required'),
    // referee1Email: Yup.string().email('Invalid email address').required('Referee 1 email is required'),
    supportingStatement: Yup.string().required('Supporting statement is required'),
    disability: Yup.string().required('Disability information is required'),
    declaration: Yup.string().required('Declaration is required'),
    proposed_start_date: Yup.date().required('Proposed start date is required'),
    time: Yup.string().required('Time is required'),
  });


  const initialValues = {
    title: '',
    apply_date: '',
    time: "",
    level_of_entry: '',
    personal_title: '',
    first_name: '',
    last_name: '',
    gender: '',
    date_of_birth: '',
    applicant_name: 'N/A',
    maidenName: '',
    family_name: '',
    p_address: '',
    country: '',
    post_code: '',
    correspondenceAddress: '',
    correspondenceCountry: '',
    correspondencePostcode: '',
    daytime_telephone: '',
    evening_telephone: '',
    mobile: '',
    email_address: '',
    nationality: '',
    arrivalDateUK: '',
    visa_type: '',
    professionalBodyName: '',
    professionalBodyRegNo: '',
    previousStudyUK: false,
    previousStudyLevel: '',
    previousStudyLCPS: false,
    highestQualification: '',
    pre_studies: [{ institution: '', qualification: '', year: '' }],
    pre_employers: [{ employer: '', position: '', years: '' }],
    agentUsed: false,
    agentName: '',
    agentEmail: '',
    referee1Name: '',
    referee1Address: '',
    referee1Postcode: '',
    referee1Telephone: '',
    referee1Email: '',
    supportingStatement: '',
    disability: '',
    declaration: false,
    proposed_start_date: '',
    document_one: '',
    document_two: '',
    document_three: '',
    document_four: '',
    // time: '12:12',
  };

  const { mutate: submitStudentForm, isLoading: isMutationLoading } = useAddStudentFormMutation();

  const navigate = useNavigate();


  // const faorm = useCustomFormik({ onSubmit, validationSchema, initialValues });
  const { data: Modules, isLoading: isGetModulesLoading, refetch: refetchModules } = useGetDiplomaWithoutAuthQuery();
  const [ModuleOptions, setModuleOptions] = useState([{ label: "Loading...", value: "" }])
  const [NationalityOptions, setNationalityOptions] = useState([])
  useEffect(() => {
    setNationalityOptions(
      nationalities.map((ele,id) => {
        return {
          label: ele,
          value: `id-${id}`,
        }
      })
    )
  }, [])

  useEffect(() => {
    if (Modules?.data.length) {
      setModuleOptions(
        Modules?.data.map(ele => {
          return {
            label: ele.name,
            value: ele.id,
          }
        })
      );
    }
  }, [Modules, isGetModulesLoading])

  return (
    <StudentContainer>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          console.log(values, "values");

            await submitStudentForm({ data: values }, {
              onSuccess: () => {
              navigate("/")
              },
            });
        }}        
      >
        {({ values, handleSubmit, setFieldValue, handleChange, errors, isSubmitting}) => (<div className='max-w-screen-md mx-auto flex flex-col gap-4 py-[2rem]'>
          <h2 className='text-center text-[2.5rem]'>Application Form for Admission</h2>

          <h3 className='text-[1.65rem]'>Diploma Details</h3>
          {/* <Input onChange={handleChange} name={"title"} placeholder="Enter Name of diploma" label={'Name of diploma'} /> */}
          <Dropdown
            onChange={(value) => {
              setFieldValue("title", value.label)
            }}
            value={ModuleOptions.filter(item => item.label == values.title)}
            placeholder={"Select Degree/Diploma/Certi. (Dpt.)"}
            title={"Name of Degree/Diploma/Certi. (Dpt.)"}
            name={"title"}
            error={errors.title}
            options={ModuleOptions}
          />
          {/* <Input onChange={handleChange} name={"apply_date"} type="date" placeholder="Enter Apply date" label={'Apply date'} /> */}
          <Dropdown
            onChange={(value) => {
              setFieldValue("time", value.value);
            }}
            value={[{ value: "Full-time", label: 'Full-time' }, { value: "Part-time", label: 'Part-time' }].find(item => item.value === values.time)}
            placeholder={"Select Full-time/Part-time"}
            title={"Full-time/Part-time"}
            name={"time"}
            options={[{ value: "Full-time", label: 'Full-time' }, { value: "Part-time", label: 'Part-time' }]}
          />
          <Input onChange={handleChange} name={"level_of_entry"} placeholder="Enter Proposed Year/Level of Entry" label={'Proposed Year/Level of Entry'} />

          <h3 className='text-[1.65rem]'>Personal Details</h3>
          <Input onChange={handleChange} name={"personal_title"} placeholder="Enter Title" label={'Title'} />
          <Input onChange={handleChange} name={"first_name"} placeholder="Enter First Name" label={'First Name'} />
          {/* <Input onChange={handleChange} name={"last_name"} placeholder="Enter Last Name" label={'Last Name'} /> */}
          {/* <Input onChange={handleChange} name={"applicant_name"} placeholder="Enter Applicant Name" label={'Applicant Name'} /> */}
          <Input onChange={handleChange} name={"maidenName"} placeholder="Enter Maiden or Other Name" label={'Maiden or Other Name'} />
          <Input onChange={handleChange} name={"family_name"} placeholder="Enter Surname/Family Name" label={'Surname/Family Name'} />
          <Dropdown
            onChange={(value) => {
              setFieldValue("gender", value.value);
            }}
            value={[{ value: 'male', label: 'Male' }, { value: 'female', label: 'Female' }, { value: 'other', label: 'Other' }].find(item => item.value === values.gender)}
            placeholder={"Select Gender"}
            title={"Gender"}
            name={"gender"}
            options={[{ value: 'male', label: 'Male' }, { value: 'female', label: 'Female' }, { value: 'other', label: 'Other' }]}
          />
          <Input onChange={handleChange} name={"date_of_birth"} type="date" placeholder="Enter Date of Birth" label={'Date of Birth'} />
          <Input onChange={handleChange} name={"p_address"} placeholder="Enter Permanent Address" label={'Permanent Address'} />
          <Input onChange={handleChange} name={"country"} placeholder="Enter Country" label={'Country'} />
          <Input onChange={handleChange} name={"post_code"} placeholder="Enter Postcode" label={'Postcode'} />
          <Input onChange={handleChange} name={"correspondenceAddress"} placeholder="Enter Correspondence Address" label={'Correspondence Address'} />
          <Input onChange={handleChange} name={"correspondenceCountry"} placeholder="Enter Correspondence Country" label={'Correspondence Country'} />
          <Input onChange={handleChange} name={"correspondencePostcode"} placeholder="Enter Correspondence Postcode" label={'Correspondence Postcode'} />
          <Input onChange={handleChange} name={"daytime_telephone"} placeholder="Enter Daytime Telephone" label={'Daytime Telephone'} />
          <Input onChange={handleChange} name={"evening_telephone"} placeholder="Enter Evening Telephone" label={'Evening Telephone'} />
          <Input onChange={handleChange} name={"mobile"} placeholder="Enter Mobile" label={'Mobile'} />
          <Input onChange={handleChange} name={"email_address"} type="email" placeholder="Enter Email Address" label={'Email Address'} />

          <Dropdown
            onChange={(value) => {
              setFieldValue("nationality", value.label)
            }}
            value={NationalityOptions.filter(item => item.label == values.nationality)}
            placeholder={"Select Nationality"}
            title={"Nationality"}
            name={"nationality"}
            error={errors.title}
            options={NationalityOptions}
          />

          {/* <Input onChange={handleChange} name={"nationality"} placeholder="Enter Nationality" label={'Nationality'} /> */}

          <Input onChange={handleChange} name={"arrivalDateUK"} type="date" placeholder="Enter Date of Arrival in UK (if not born in the UK)" label={'Date of Arrival in UK (if not born in the UK)'} />
          <Input onChange={handleChange} name={"visa_type"} placeholder="Enter type of visa" label={'Type of visa'} />

          <h3 className='text-[1.65rem]'>Professional Body Membership</h3>
          <Input onChange={handleChange} name={"professionalBodyName"} placeholder="Enter Professional Body Name" label={'Professional Body Name'} />
          <Input onChange={handleChange} name={"professionalBodyRegNo"} placeholder="Enter Professional Body Registration Number" label={'Professional Body Registration Number'} />

          <h3 className='text-[1.65rem]'>Previous Study</h3>
          <Dropdown
            onChange={(value) => {
              setFieldValue("previousStudyUK", value.value === 'yes');
            }}
            value={[
              { value: 'yes', label: 'Yes' },
              { value: 'no', label: 'No' },
            ].find(item => item.value === (values.previousStudyUK ? 'yes' : 'no'))}
            placeholder={"Have you previously studied in the UK?"}
            title={"Have you previously studied in the UK?"}
            name={"previousStudyUK"}
            options={[
              { value: 'yes', label: 'Yes' },
              { value: 'no', label: 'No' },
            ]}
          />
          <Input onChange={handleChange} name={"previousStudyLevel"} placeholder="Enter Level" label={'If yes, at what level?'} />
          <Dropdown
            onChange={(value) => {
              setFieldValue("previousStudyLCPS", value.value === 'yes');
            }}
            value={[
              { value: 'yes', label: 'Yes' },
              { value: 'no', label: 'No' },
            ].find(item => item.value === (values.previousStudyLCPS ? 'yes' : 'no'))}
            placeholder={"Have you previously studied at LCPS?"}
            title={"Have you previously studied at LCPS?"}
            name={"previousStudyLCPS"}
            options={[
              { value: 'yes', label: 'Yes' },
              { value: 'no', label: 'No' },
            ]}
          />
          <Input onChange={handleChange} name={"highestQualification"} placeholder="Enter Highest Qualification" label={'Highest Qualification'} />

          <h3 className='text-[1.65rem]'>Academic and Professional Qualifications</h3>
          <FieldArray validateOnChange={false} name="pre_studies">
            {({ push, remove }) => (
              <div>
                {/* <h3>Pre Studies</h3> */}
                {values.pre_studies.map((_, index) => (
                  <div className='mb-3 flex flex-col gap-3' key={index}>
                    <Input
                      onChange={(e) => {
                        setFieldValue(`pre_studies[${index}].institution`, e.target.value)
                      }}
                      name={`pre_studies[${index}].institution`}
                      placeholder="Enter Institution"
                      label="Institution"
                    />
                    <Input
                      onChange={(e) => {
                        setFieldValue(`pre_studies[${index}].qualification`, e.target.value)
                      }}
                      name={`pre_studies[${index}].qualification`}
                      placeholder="Enter Qualification"
                      label="Qualification"
                    />
                    <Input
                      onChange={(e) => {
                        setFieldValue(`pre_studies[${index}].year`, e.target.value)
                      }}
                      name={`pre_studies[${index}].year`}
                      placeholder="Enter Year"
                      label="Year"
                    />
                    <Button onClick={() => remove(index)}>Remove</Button>
                  </div>
                ))}
                <Button onClick={() => push({ institution: '', qualification: '', year: '' })}>Add Study</Button>
              </div>
            )}
          </FieldArray>

          <h3 className='text-[1.65rem]'>Employment and Work Experience</h3>
          <FieldArray validateOnChange={false} name="pre_employers">
            {({ push, remove }) => (
              <div>
                {values.pre_employers.map((_, index) => (
                  <div className='mb-3 flex flex-col gap-3' key={index}>
                    <Input
                      onChange={(e) => {
                        setFieldValue(`pre_employers[${index}].employer`, e.target.value)
                      }}
                      name={`pre_employers[${index}].employer`}
                      placeholder="Enter Employer Name"
                      label="Employer Name"
                    />
                    <Input
                      onChange={(e) => {
                        setFieldValue(`pre_employers[${index}].position`, e.target.value)
                      }}
                      name={`pre_employers[${index}].position`}
                      placeholder="Enter Position"
                      label="Position"
                    />
                    <Input
                      onChange={(e) => {
                        setFieldValue(`pre_employers[${index}].years`, e.target.value)
                      }}
                      name={`pre_employers[${index}].years`}
                      placeholder="Enter Years of experience"
                      label="Years of experience"
                    />
                    <Button onClick={() => remove(index)}>Remove</Button>
                  </div>
                ))}
                <Button onClick={() => push({ employer: '', position: '', years: '' })}>Add Employer</Button>
              </div>
            )}
          </FieldArray>

          <h3 className='text-[1.65rem]'>Agent Details</h3>
          <Dropdown
            onChange={(value) => {
              setFieldValue("agentUsed", value.value === 'yes');
            }}
            value={[
              { value: 'yes', label: 'Yes' },
              { value: 'no', label: 'No' },
            ].find(item => item.value === (values.agentUsed ? 'yes' : 'no'))}
            placeholder={"Have you used an education agent during your application process?"}
            title={"Have you used an education agent during your application process?"}
            name={"agentUsed"}
            options={[
              { value: 'yes', label: 'Yes' },
              { value: 'no', label: 'No' },
            ]}
          />
          {values.agentUsed && (
            <>
              <Input onChange={handleChange} name={"agentName"} placeholder="Enter Agent Name" label={'Agent Name'} />
              <Input onChange={handleChange} name={"agentEmail"} type="email" placeholder="Enter Agent Email" label={'Agent Email'} />
            </>
          )}

          {/* <h3 className='text-[1.65rem]'>References</h3>
          <Input onChange={handleChange} name={"referee1Name"} placeholder="Enter Referee Name" label={'Referee 1 Name'} />
          <Input onChange={handleChange} name={"referee1Address"} placeholder="Enter Referee Address" label={'Referee 1 Address'} />
          <Input onChange={handleChange} name={"referee1Postcode"} placeholder="Enter Referee Postcode" label={'Referee 1 Postcode'} />
          <Input onChange={handleChange} name={"referee1Telephone"} placeholder="Enter Referee Telephone" label={'Referee 1 Telephone'} />
          <Input onChange={handleChange} name={"referee1Email"} type="email" placeholder="Enter Referee Email" label={'Referee 1 Email'} /> */}
          {/* Repeat for other referees if necessary */}

          <h3 className='text-[1.65rem]'>Supporting Statement</h3>

          <textarea className='bg-gray-50 border border-[#00000099] text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 outline-none' onChange={handleChange} name={"supportingStatement"} placeholder="Enter Supporting Personal Statement" label={'Supporting Personal Statement'} rows={7}></textarea>

          <h3 className='text-[1.65rem]'>Disability</h3>
          <Input onChange={handleChange} name={"disability"} placeholder="Enter Disability" label={'Disability'} />

          <h3 className='text-[1.65rem]'>Declaration</h3>
          <Dropdown
            onChange={(value) => {
              setFieldValue("declaration", value.value);
            }}
            value={[
              { value: 'yes', label: 'Yes' },
              { value: 'no', label: 'No' },
            ].find(item => item.value === (values.declaration ? 'yes' : 'no'))}
            placeholder={"I declare that the information provided is true and complete"}
            title={"Declaration"}
            name={"declaration"}
            options={[
              { value: 'yes', label: 'Yes' },
              { value: 'no', label: 'No' },
            ]}
          />

          <Input onChange={handleChange} name={"apply_date"} type="date" placeholder="Enter Date Applied" label={'Date Applied'} />
          <Input onChange={handleChange} name={"proposed_start_date"} type="date" placeholder="Enter Proposed Start Date" label={'Proposed Start Date'} />
          
          <Input onChange={(e)=>{

            setFieldValue("document_one", e.target.files[0])
          }} type="file" placeholder="Upload Document 1" label={'Document 1'} name={"document_one"} />
          <Input onChange={(e)=>{
            setFieldValue("document_two", e.target.files[0])
          }} type="file" placeholder="Upload Document 2" label={'Document 2'} name={"document_two"} />
          <Input onChange={(e)=>{
            setFieldValue("document_three", e.target.files[0])
          }} type="file" placeholder="Upload Document 3" label={'Document 3'} name={"document_three"} />
          <Input onChange={()=>{}} type="file" placeholder="Upload Document 4" label={'Document 4'} name={"document_four"} />

          {
            Object.keys(errors).length ? (
              <div className='bg-red-100 border-red-400 text-red-400 p-3 rounded-xl'>
                <ul>
                  {Object.entries(errors).map(([field, error]) => (
                    <li key={field}>{`${field}: ${error}`}</li>
                  ))}
                </ul>
              </div>
            ) : ""
          }
          <Button isLoading={isSubmitting || isMutationLoading} onClick={handleSubmit} type="submit">Submit</Button>
        </div>)}
      </Formik>
    </StudentContainer>
  );
};

export default StudentForm;