import { course } from 'data/api';
import { useQuery, useMutation } from 'react-query';
import { GET } from 'api/common';

export const getSingleModuleData = async (id) => {
    if (id) {
        const data = await GET(`${course.get_single_course}/${id}`, null);
        return data;
    }
};

export const useSingleModuleQuery = (id) => {
    return useQuery('GetSingleModule', () => getSingleModuleData(id));
};

export const useGetModuleMutation = () => {
    return useMutation(getSingleModuleData);
};
