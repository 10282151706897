import { useGetStudentFormsQuery } from 'api/student/get-student-applications';
import SmallLoader from 'components/common/elements/loaders/small-loader';
import Popup from 'components/common/elements/popup';
import DashboardContainer from 'components/layout/dashboard-container';
import { BASE_URL_IMG } from 'data/api';
import React, { useState } from 'react';
import Svgs from 'svgs';

const ApplicationView = () => {
    const { data: Applications, isLoading: isGetApplicationsLoading } = useGetStudentFormsQuery();
    const [View, setView] = useState({ open: false, data: null });
    console.log(Applications);
    return (
        <DashboardContainer active={"Applications"}>
            <div className="flex flex-col gap-5">
                <div className='flex items-center gap-3 justify-between'>
                    <h1 className="text-2xl">Application requests</h1>
                </div>
                <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 border">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                            <tr>
                                <th scope="col" className="p-4">
                                    <div className="flex items-center">
                                        #
                                    </div>
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    <p className='whitespace-nowrap'>Title</p>
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    <p className='whitespace-nowrap'>Start Date</p>
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    <p className='whitespace-nowrap'>Email</p>
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    <p className='whitespace-nowrap'>Action</p>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {!isGetApplicationsLoading && Applications?.data?.reverse().map((ele, i) => (
                                <tr key={ele.id} className="bg-white border-b hover:bg-gray-50">
                                    <td className="w-4 p-4">
                                        <code className='whitespace-nowrap bg-gray-50 px-1 border rounded-md'>{i +1}</code>
                                    </td>
                                    <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                        {ele.personal_title} {ele.first_name} {ele.family_name}
                                    </td>
                                    <td className="px-6 py-4">
                                        {ele.apply_date}
                                    </td>
                                    <td className="px-6 py-4">
                                        {ele.email_address}
                                    </td>
                                    <td>
                                        <div className='cursor-pointer mx-auto w-fit' onClick={() => setView({ open: true, data: ele })}>
                                            <Svgs.Eye />
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {isGetApplicationsLoading && <SmallLoader />}
                </div>
            </div>

            <Popup heading={"Applications Details"} open={View.open} close={() => setView({ open: false, data: null })}>
                {View.data && (
                    <div className='grid grid-cols-2 gap-4'>
                        <div className='flex flex-col gap-0.5 border p-2 rounded-md'>
                            <h1>Title</h1>
                            <p className='text-sm'>{View.data.title}</p>
                        </div>
                        <div className='flex flex-col gap-0.5 border p-2 rounded-md'>
                            <h1>Start Date</h1>
                            <p className='text-sm'>{View.data.proposed_start_date}</p>
                        </div>
                        <div className='flex flex-col gap-0.5 border p-2 rounded-md'>
                            <h1>Time</h1>
                            <p className='text-sm'>{View.data.time}</p>
                        </div>
                        <div className='flex flex-col gap-0.5 border p-2 rounded-md'>
                            <h1>Level of Entry</h1>
                            <p className='text-sm'>{View.data.level_of_entry}</p>
                        </div>
                        <div className='flex flex-col gap-0.5 border p-2 rounded-md'>
                            <h1>Name</h1>
                            <p className='text-sm'>{View.data.personal_title} {View.data.first_name} {View.data.family_name}</p>
                        </div>
                        <div className='flex flex-col gap-0.5 border p-2 rounded-md'>
                            <h1>Gender</h1>
                            <p className='text-sm'>{View.data.gender}</p>
                        </div>
                        <div className='flex flex-col gap-0.5 border p-2 rounded-md'>
                            <h1>Date of Birth</h1>
                            <p className='text-sm'>{View.data.date_of_birth}</p>
                        </div>
                        <div className='flex flex-col gap-0.5 border p-2 rounded-md'>
                            <h1>Address</h1>
                            <p className='text-sm'>{View.data.p_address}</p>
                        </div>
                        <div className='flex flex-col gap-0.5 border p-2 rounded-md'>
                            <h1>Country</h1>
                            <p className='text-sm'>{View.data.country}</p>
                        </div>
                        <div className='flex flex-col gap-0.5 border p-2 rounded-md'>
                            <h1>Post Code</h1>
                            <p className='text-sm'>{View.data.post_code}</p>
                        </div>
                        <div className='flex flex-col gap-0.5 border p-2 rounded-md'>
                            <h1>Daytime Telephone</h1>
                            <p className='text-sm'>{View.data.daytime_telephone}</p>
                        </div>
                        <div className='flex flex-col gap-0.5 border p-2 rounded-md'>
                            <h1>Mobile</h1>
                            <p className='text-sm'>{View.data.mobile}</p>
                        </div>
                        <div className='flex flex-col gap-0.5 border p-2 rounded-md'>
                            <h1>Email</h1>
                            <p className='text-sm'>{View.data.email_address}</p>
                        </div>
                        <div className='flex flex-col gap-0.5 border p-2 rounded-md'>
                            <h1>Nationality</h1>
                            <p className='text-sm'>{View.data.nationality}</p>
                        </div>
                        <div className='flex flex-col gap-0.5 border p-2 rounded-md'>
                            <h1>Visa Type</h1>
                            <p className='text-sm'>{View.data.visa_type}</p>
                        </div>
                        <div className='flex flex-col gap-0.5 border p-2 rounded-md'>
                            <h1>Disability</h1>
                            <p className='text-sm'>{View.data.disability}</p>
                        </div>
                        <div className='flex flex-col gap-0.5 border p-2 rounded-md'>
                            <h1>Declaration</h1>
                            <p className='text-sm'>{View.data.declaration}</p>
                        </div>
                        <div className='flex flex-col gap-0.5 border p-2 rounded-md'>
                            <h1>Applicant Name</h1>
                            <p className='text-sm'>{View.data.applicant_name}</p>
                        </div>
                        <div className='flex flex-col gap-0.5 border p-2 rounded-md'>
                            <h1>Apply Date</h1>
                            <p className='text-sm'>{View.data.apply_date}</p>
                        </div>
                        <div className='flex flex-col gap-0.5 border p-2 rounded-md col-span-2'>
                            <h1 className='text-xl font-semibold text-center'>Attached Documents</h1>
                        </div>
                        <div className='flex flex-col gap-0.5 border p-2 rounded-md justify-center'>
                            {
                                View?.data?.document_one ? <a className="text-xs text-blue-500 underline" target='_blank' href={`${BASE_URL_IMG}${View?.data?.document_one}`}>
                                    Document one
                                </a> : <div className='text-center'>---</div>
                            }

                        </div>
                        <div className='flex flex-col gap-0.5 border p-2 rounded-md justify-center'>
                            {
                                View?.data?.document_two ? <a className="text-xs text-blue-500 underline" target='_blank' href={`${BASE_URL_IMG}${View?.data?.document_two}`}>
                                    Document two
                                </a> : <div className='text-center'>---</div>
                            }

                        </div>
                        <div className='flex flex-col gap-0.5 border p-2 rounded-md justify-center'>
                            {
                                View?.data?.document_three ? <a className="text-xs text-blue-500 underline" target='_blank' href={`${BASE_URL_IMG}${View?.data?.document_three}`}>
                                    Document three
                                </a> : <div className='text-center'>---</div>
                            }


                        </div>
                        <div className='flex flex-col gap-0.5 border p-2 rounded-md justify-center'>
                            {
                                View?.data?.document_four ? <a className="text-xs text-blue-500 underline" target='_blank' href={`${BASE_URL_IMG}${View?.data?.document_four}`}>
                                    Document four
                                </a> : <div className='text-center'>---</div>
                            }

                        </div>
                    </div>
                )}
            </Popup>
        </DashboardContainer>
    );
};

export default ApplicationView;
