import { course, diploma } from 'data/api';
import { useQuery, useMutation } from 'react-query';
import { GET } from 'api/common';

export const getDiplomaWithoutAuthData = async () => {
    const data = await GET(diploma.get_diploma_no_auth, null);
    return data;
};

export const useGetDiplomaWithoutAuthQuery = () => {
    return useQuery('GetDiplomaWithoutAuth', getDiplomaWithoutAuthData);
};

export const useGetDiplomaWithoutAuthMutation = () => {
    return useMutation(getDiplomaWithoutAuthData);
};
