import { student } from 'data/api';
import { useQuery, useMutation } from 'react-query';
import { toast } from 'react-hot-toast';
import { POST, postJSON } from 'api/common';

export const addStudentForm = async (params) => {
    const values = { ...params.data };
    values.pre_employers = values.pre_employers ? JSON.stringify(values.pre_employers) : JSON.stringify([]);
    values.pre_studies = values.pre_studies ? JSON.stringify(values.pre_studies) : JSON.stringify([]);
    const URL = student.add_student_form
    const response = await POST(URL, values, () => {
        toast.success(`Application request sent successfully!`);
    });
    // response.pre_employers = response.pre_employers ? JSON.parse(response.pre_employers) : JSON.parse([]);
    // response.pre_studies = response.pre_studies ? JSON.parse(response.pre_studies) : JSON.parse([]);
    return response;
};

export const useAddStudentFormQuery = () => {
    return useQuery('AddStudentForm', addStudentForm);
};

export const useAddStudentFormMutation = () => {
    return useMutation(addStudentForm)
};
