import Svgs from "svgs";
import Dashboard from "views/admin/dashboard";
import Teachers from "views/admin/teachers";
import Students from "views/admin/students";
import Modules from "views/admin/modules";
import Department from "views/admin/department";
import Contact from "views/admin/contact";
import Lectures from "views/teacher/lectures";
import ApplicationView from "views/admin/view-applications";

export const adminRouteList = [
  {
    path: "/admin/",
    element: <Dashboard />,
    name: "Home",
    icon: <Svgs.Home />,
  },
  {
    path: "/admin/teachers/",
    element: <Teachers />,
    name: "Teachers",
    icon: <Svgs.Teachers />,
  },
  {
    path: "/admin/students/",
    element: <Students />,
    name: "Students",
    icon: <Svgs.Students />,
  },
  {
    path: "/admin/modules/",
    element: <Modules />,
    name: "Modules",
    icon: <Svgs.Modules />,
  },
  {
    path: "/admin/department/",
    element: <Department />,
    name: "Department",
    icon: <Svgs.Department />,
  },
  {
    path: "/admin/lectures/",
    element: <Lectures />,
    name: "Lectures",
    icon: <Svgs.Lectures />,
  },
  {
    path: "/admin/contact/",
    element: <Contact />,
    name: "Contact",
    icon: <Svgs.Contact />,
  },
  {
    path: "/admin/application/",
    element: <ApplicationView />,
    name: "Applications",
    icon: <Svgs.Contact />,
  }
];
